import styled from "styled-components";
import React from "react";

const ToggleButtonWrapper = styled.div`
  display: ${({isMobileView}) => (isMobileView ? "flex" : "none")};
  //display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 30px;
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
  opacity: 1;
  z-index: 20;

  span {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #000000;
    border-radius: 3px;
    z-index: 20;
    transform-origin: 5px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0 0;
  }

  span:nth-last-child(2) {
    transform-origin: 0 100%;
  }

  &.toggled span:first-child {
    transform: rotate(45deg) translate(-3px, 0);
  }

  &.toggled span:nth-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  &.toggled span:nth-last-child(1) {
    transform: rotate(-45deg) translate(0, -6px);
  }
`;

const toggleButton = ({isMenuOpen, isMobileView, handleClick}) => {
  return (
    <ToggleButtonWrapper
      className={isMenuOpen && "toggled"}
      onClick={() => handleClick()}
      isMobileView={isMobileView}
    >
      <span />
      <span />
      <span />
    </ToggleButtonWrapper>
  );
};

export default toggleButton;
