import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const PaintingWrapper = styled.div`

  & a {
    text-decoration: none;
  }

  & img {
    opacity: 1;
    width: 100%;
    max-width: 100%;
    transition: 0.5s ease;
  }

  & img:hover {
    opacity: 0.6;
  }

  & p {
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: initial;
  }
`;

const Painting = ({painting, isLast}) => {
  return (
    <PaintingWrapper isLast={isLast}>
      <Link to={`paintings/${painting.name.toLowerCase()}`}>
        <img src={painting.pathname} alt={painting.name} />
        <p>{painting.name}</p>
      </Link>
    </PaintingWrapper>
  );
};

export default Painting;
