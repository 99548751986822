import React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import styles from '../../../UI/Layout.module.css'


const useStyles = makeStyles({
  figure: {
    "marginLeft": "0",
    "& img": {
      width: "100%",
      height: 400,
    },
    height: 400,
  },
});
const NewsPage = () => {
  const classes = useStyles();
  return (
    <Grid container className={styles.fadeIn}>
      <figure className={classes.figure}>
        <a href="http://www.yarrasculpturegallery.com.au/past.html">
          <img src="image/news/dimensions.jpg"
               alt="DIMENSIONS" />
        </a>
      </figure>
      <figure className={classes.figure}>
        <img src="image/news/Jingjiyi.png"
             alt="Hebei Exhibition" />
      </figure>
    </Grid>
  );
};
export default NewsPage;