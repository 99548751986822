import React from "react";
import {Route, Switch} from "react-router-dom";
import Welcome from "./Welcome";
import Page from "./Page";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./UI/Layout.module.css";

function App() {
  const fontSizes = {
    primary: 55,
    secondary: 20,
    text: 16,
  };

  const mainPageRoutes = [
    "home",
    "works",
    "exhibitions",
    "publications",
    "news",
    "about",
    "contact",
  ];

  const paintings = [
    {
      layout: "column",
      name: "Please Hear Me",
      Artist: "Xiaoyu Yang (China)",
      Photo: "",
      Size: "12 m x 12 m (the size will change accordingly)",
      Year: "2022-2023",
      description:
        "It is a sound and light installation called Please Hear Me, which tells the story of the endangered plant through a ‘flower’ consisting of five ‘mouths’. The artwork installs on the lake, and visitors are expected to listen, connect and ponder it. Using the changing colour of the LED lights and the sound of the threatened flora, the artwork intends to call the public attention to the climate changes and the loss of biodiversity.",
    },
    {
      layout: "column",
      name: "Rhodiola Crenulata",
      Artist: "Xiaoyu Yang",
      Series: "Australian Endangered species",
      Photo: "Hunan Sanjiang Culture and Art Co., Ltd.",
      Size: "68 cm x 136 cm",
      Year: "2021",
      // pathName: "image/painting_details/Rhodiola Crenulata",
    },
    {
      layout: "column",
      name: "Michelia Wilsonii Finet et Gagn",
      Artist: "Xiaoyu Yang",
      Collaborator: "Huiyu Zeng",
      Series: "Chinese Endangered species",
      Photo: "Hunan Sanjiang Culture and Art Co., Ltd.",
      Size: "68 cm x 136 cm",
      Year: "2021-2022",
      description:
        "This artwork shows an endangered species that exists only in China – Michelia wilsonii Finet et Gagn, and it is threatened by habitat loss. Combining with AR techniques, this traditional Chinese painting conveys the idea that when people interact with the plant, they may become concerned about it and the environmental problem that it presents. About interacting, you need to download the app “Artivive” from App Store, then use Artivive to scan this painting, for people who use the Android system should download this app through Google Play.",
    },
    {
      layout: "column",
      name: "Changium Smyrnioides Wolff",
      Artist: "Xiaoyu Yang",
      Series: "Chinese Endangered species",
      Photo: "Hunan Sanjiang Culture and Art Co., Ltd.",
      Size: "68 cm x 136 cm",
      Year: "2020",
      // pathName: "image/painting_details/Changium Smyrnioides Wolff",
    },
    {
      layout: "column",
      name: "Time's Divergence",
      Artist: "Xiaoyu Yang",
      Medium: "Digital printing, traditional Chinese painting",
      Size: "101 cm x 164 cm",
      Year: "2020-2023",
      description: "Time’s Divergence harmoniously blends traditional Chinese painting with AI, using the endangered Chuniophoenix hainanensis Burret as a metaphor. It represents the challenges of human artistry amidst “diminishing habitats”. By combining meticulous techniques and rapid AI processes, this artwork explores the paradoxical relationship between art’s time-consuming nature and AI’s speed. It aims to awaken consciousness, showcasing art’s transformative power and prompting reflection on humanity, technology, and the need to protect fragile ecosystems.",
      // pathName: "image/painting_details/Chuniophoenix Hainanensis Burret",
    },
    {
      layout: "column",
      name: "The Floral Alarm",
      Artist: "Xiaoyu Yang",
      Series: "Chinese Endangered species",
      Photo: "Hunan Sanjiang Culture and Art Co., Ltd.",
      Size: "31.6 cm x 35.8 cm x 105",
      Year: "2018-2021",
      description:
        "There are 556 Australian endangered plant species for now. Drawing attention to environmental damage caused by individuals and groups can influence people to act and this may potentially increase biodiversity, while small changes undertaken by populations may produce accumulated change. These works are some species of Australian endangered plants’ genus. Traditional Chinese paintings of flowers originated thousands of years ago, and the Chinese paintings of Australian endangered plants indicate the wishes that endangered plants will have a chance to survive longer just like traditional Chinese paintings are inherited from generation to generation. A question to consider is how we might change to mitigate further loss.",
    },
  ];

  return (
    <Switch>
      <Route path={"/"} exact={true}>
        <Welcome />
      </Route>
      {mainPageRoutes.map((pathName) => (
        <Route path={`/${pathName}`} key={pathName}>
          <div className={styles.app}>
            <Header fontSizes={fontSizes} currentPage={pathName.toUpperCase()} />
            <Page currentPage={pathName.toUpperCase()} fontSizes={fontSizes} />
            <Footer />
          </div>
        </Route>
      ))}

      {paintings.map((painting) => {
        return (
          <Route key={painting.name} path={`/paintings/${painting.name.toLowerCase()}`}>
            <div className={styles.app}>
              <Header fontSizes={fontSizes} currentPage={"WORKS"} />
              <Page currentPage={"PAINTING"} painting={painting} fontSizes={fontSizes} />
              <Footer />
            </div>
          </Route>
        );
      })}
    </Switch>
  );
}

export default App;
