import React, {Component} from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import {Grid, Typography} from "@material-ui/core";
import styled from "styled-components";

const Button = styled.button`
	width: 190px;
	color: white;
	background-color: black;
	border-radius: 5px;
	font-size: 20px;
	padding: 10px 20px;
	cursor: pointer;
	outline: none;
	border: none;
	font-family: "Arial Narrow", serif;
`;

export default class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      message: "",
      email: "",
      subject: "",
      sent: false,
      buttonText: "Send Message",
      emailError: false,
    };
  }

  resetForm() {
    this.setState({
      name: "",
      message: "",
      email: "",
      subject: "",
      buttonText: "Message Sent",
    });

    setTimeout(() => {
      this.setState({sent: false});
    }, 3000);
  }

  handleChangeEmail(e) {
    if (
      !e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      this.setState({
        email: e.target.value,
      });
      this.setState({emailError: true});

      if (this.state.email === "") {
        // check if the input is empty
        this.setState({emailError: false});
      }
    } else {
      this.setState({email: e.target.value, emailError: false});
    }
  }

  async formSubmit(e) {
    e.preventDefault();
    this.setState({
      buttonText: "...sending",
    });

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      subject: this.state.subject,
    };

    try {
      await axios.post("BACKEND_URL", data);
      this.setState({sent: true}, this.resetForm);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <Grid container direction={"row-reverse"} justify={"center"} spacing={4}>
        <Grid item sm={12} md={8}>For enquiries, please use the contact below or simply leave
          a message:<br />Email: zoeyang262@yahoo.com</Grid>
        <Grid item sm={12} md={8}>
          <form className="contact-form" onSubmit={(e) => this.formSubmit(e)}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item sm={12}>
                <TextField
                  id="standard-full-width"
                  placeholder="Enter your name"
                  label="Name"
                  variant="outlined"
                  value={this.state.name}
                  onChange={(e) => this.setState({name: e.target.value})}
                  required
                  type="text"
                  style={{width: "100%"}}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="standard-full-width"
                  label="Email"
                  placeholder="Enter email address"
                  variant="outlined"
                  value={this.state.email}
                  onChange={(e) => this.handleChangeEmail(e)}
                  error={this.state.emailError}
                  required
                  type="email"
                  style={{width: "100%"}}
                /></Grid>

              <Grid item sm={12}><TextField
                id="standard-full-width"
                placeholder="Enter Subject"
                label="Subject"
                variant="outlined"
                value={this.state.subject}
                onChange={(e) => this.setState({subject: e.target.value})}
                style={{width: "100%"}}
              /></Grid>
              <Grid item sm={12}><TextField
                id="standard-multiline-flexible"
                label="Message"
                placeholder="Enter Message"
                variant="outlined"
                multiline
                rowsMax={4}
                value={this.state.message}
                onChange={(e) => this.setState({message: e.target.value})}
                type="text"
                rows={4}
                style={{width: "100%"}}
              />
              </Grid>
              <Grid item>
                <Button type="submit">
                  {this.state.buttonText}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}