import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Buttons, Indicator} from "./components";
import {Box} from "@material-ui/core";

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: ${(props) => `${props.size.height}`};
	overflow: hidden;
`;
const Slide = styled.div`
	display: flex;
	transform: ${({xPosition}) => `translateX(${xPosition}px)`}; // move to next slide
	transition: ${({isAnimated}) => isAnimated && `transform 0.8s ease-in-out`};

	& .imageBox {
		& img {
			max-width: 100%;
        width: 100%;
        //max-height: 600px;
        //overflow: hidden;
		//    object-fit: cover
		}
      & video {
          width: 100%;
          height: 626.5px;
      }
	}
`;

const Carousel = ({images, pause, autoplay, ...props}) => {
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [xPosition, setXPosition] = useState(0);
  const [isAnimated, setIsAnimated] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [darkImageIndexes, setDarkImageIndexes] = useState([])

  const handleHover = (hovered) => {
    pause && setIsHovered(hovered);
  };
  const handleClickPrev = () => {
    setIsAnimated(true);
    if (index === 0) return;
    setIndex(index - 1);
    setXPosition(xPosition + width);
  };

  const handleClickNext = () => {
    setIsAnimated(true);
    if (index === images.length - 1) {
      setIndex(0);
      setXPosition(0);
    } else {
      setIndex(index + 1);
      setXPosition(xPosition - width);
    }
  };
  const slideRef = useRef();

  const changeSlideHandler = (index) => {
    setIsAnimated(true);
    setIndex(index);
    setXPosition(xPosition - index * width);
  };
  useEffect(() => {
    if (autoplay && !isHovered) {
      const handleAutoplay = setInterval(handleClickNext, 5000);
      return () => clearInterval(handleAutoplay);
    }
  }, [isHovered]);

  useEffect(() => {
    // console.log("reached here");
    const updateSize = () => {
      if (slideRef.current) {
        const width = slideRef.current.clientWidth;
        setWidth(width);
        setXPosition(-(width * index));
      }
    };
    updateSize();

    const handleResize = () => {
      setIsAnimated(false);
      // console.log(isAnimated);
      updateSize();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setWidth, index, isAnimated, xPosition]);

  return (
    <Wrapper
      className={"carouselWrapper"}
      size={props.size || "100%"}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <Slide xPosition={xPosition} ref={slideRef} isAnimated={isAnimated}>
        {images.map((image, i) => {
          image.isDarkImage && !darkImageIndexes.includes(i) && setDarkImageIndexes((prevState => [...prevState, i]))
          return (
            <Box width={1} maxWidth={1} flexShrink={0} className={"imageBox"} key={i}>
              {image.path.slice(-3) === "mp4" ? <video autoPlay={true}>
                <source src={image.path} type="video/mp4" />
              </video> : <img src={image.path} alt={image.alt} key={i} />}
            </Box>
          );
        })}
      </Slide>
      <Buttons handleClickPrev={handleClickPrev} handleClickNext={handleClickNext} color={darkImageIndexes.includes(index) ? 'secondary': 'default'}/>
      <Indicator
        numImages={images.length}
        currentImageIndex={index}
        changeSlideHandler={changeSlideHandler}
      />
    </Wrapper>
  );
};

export default Carousel;
