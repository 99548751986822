import React, { Fragment } from "react";
import styled from "styled-components";
import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import FadeIn from "react-fade-in";

const exhibitions_details = {
  2023: {
    February: [<><a href="https://artspaces.kunstmatrix.com/en/exhibition/10914798/through-time-and-space-%E6%97%B6%E7%A9%BA%E4%B9%8B%E6%97%85">Through Time and Space</a>, Museum of Chinese Australian History/Australian Embassy, Melbourne (Online)/ Beijing, Australia/China</>],
  },
  2022: {
    September: ["The Second Essere International Youth Art and Design Exhibition. Accademia di belle arti di Brera, Milan (Online), Italy."],
    July: ["China· Hainan Sanya Yazhou Bay Science and Technology City Public Art Creation Competition, Sanya, China"],
  },
  2021: {
    December: ["The First Invitational Exhibition of Designer Artworks in Hunan Province"],
    September: [
      "Changsha Excellent Art Exhibition, Changsha, China",
    ],
    August: [
      "The second Beijing-Tianjing-Hebei Youth Art Biennale Exhibition, MCC Art Gallery, Shijiazhuang, China",
    ],
    May: [
      "Flora Alarm,  LUX Gallery, Cairns, Australia",
      "The 2nd Hebei Youth Art Exhibition and National Invitation Exhibition of Outstanding Young Artists, China",
    ],
    March: ["Hebei Female Artists Online Art Exhibition for Women’s Day, China"],
  },
  2020: {
    November: ["National College Students’ Arts Exhibition, China"],
    September: ["National University Students Graduation Arts Exhibition, China"],
    March: ["Dimensions, Yarra Sculpture Gallery, Melbourne, Australia"],
  },
  2019: {
    July: [
      "The Grevillea Alarm, Glass Box, Queensland College of Art, Brisbane, Australia",
    ],
  },
  2017: {
    April: [
      "Undergraduate Students Exhibitions, Houhu International Art Center, Changsha, China",
    ],
  },
  2015: { November: ["Yunnan Sketches Exhibition, Changsha, China"] },
};

const Exhibitions = styled.div`
	& .card {
		margin: 0 0 32px;
		font-family: "Arial Narrow", sans-serif;
		border: 0;
	}

	& .year,
	.month {
		font-weight: 600;
		font-family: "Arial Narrow", serif;
	}
`;

const useStyle = makeStyles({
  root: {
    fontFamily: "Arial Narrow",
  },
});

const ExhibitionsPage = () => {
  const classes = useStyle();
  return (
    <Exhibitions>
      <FadeIn>
        {Object.entries(exhibitions_details)
          .reverse()
          .map(([year, exhibitions]) => {
            return (
              <Card className={`card ${classes.root}`} variant="outlined" key={year}>
                <Typography variant="h5" className={`year ${classes.root}`} component="h3">
                  {year}
                </Typography>
                {Object.entries(exhibitions).map(([month, exhibition_detail]) => (
                  <Grid container key={month}>
                    <Grid item xs={12} md={2}>
                      <Typography variant="body1" className={`month ${classes.root}`} component="h4">
                        {month}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Typography variant="body1" className={classes.root} component="p">
                        {exhibition_detail.map((text, i) => {
                          return (
                            <Fragment key={i}>
                              {text}
                              {i < exhibition_detail.length && <br />}
                            </Fragment>
                          );
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            );
          })}
      </FadeIn>
    </Exhibitions>
  );
};

export default ExhibitionsPage;
