import {Button, makeStyles} from "@material-ui/core";
import React from "react";
import "./welcome.css";
import {Link} from "react-router-dom";

const Welcome = (props) => {
  return (
    <div className="welcome">
      <div className="welcome__center">
        <p>XIAOYU YANG</p>
        <Link to={"/home"}>
          <Button type={"submit"} variant="outlined">
            ENTER
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
