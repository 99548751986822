import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import {Link} from "react-router-dom";
import ToggleButton from "./ToggleButton";

const NavbarList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 12;
  background: white;

  & .navbar__list__item {
    list-style: none;
  }

  & .navbar__list__item a {
    color: inherit;
    text-decoration: none;
    font-size: ${({fontSizes}) => (fontSizes ? fontSizes.secondary : 25)}px;
    transition: all 0.2s ease-in-out;
  }

  & .navbar__list__item a:hover,
  .navbar__list__item a.active {
    color: #cace91;
    transition: all 0.2s ease-in-out;
  }

  & .navbar__list__item a.active {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    height: ${({isMenuOpen}) => (isMenuOpen ? "100%" : 0)};
    top: 0;
    right: 0;
    opacity: ${({isMenuOpen}) => (isMenuOpen ? 1 : 0)};
    transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    //transform: translateX(50%);
    .navBarList__container {
      display: ${({isMenuOpen}) => (isMenuOpen ? "flex" : "none")};
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 50%;
      top: 50%;
      transform: translateY(-50%);
      user-select: none;
      //background: none;
    }
  }
`;

const Navbar = ({currentPage, fontSizes, isMenuOpen, isMobileView}) => {
  const navBarItems = [
    {href: "HOME", content: "HOME"},
    {href: "WORKS", content: "WORKS"},
    {href: "EXHIBITIONS", content: "EXHIBITIONS"},
    {href: "PUBLICATIONS", content: "PUBLICATIONS"},
    {href: "NEWS", content: "NEWS"},
    {href: "ABOUT", content: "ABOUT"},
    {href: "CONTACT", content: "CONTACT"},
  ];

  return (
    <NavbarList fontSizes={fontSizes} isMenuOpen={isMenuOpen}>
      <Grid
        container
        spacing={2}
        className={"navBarList__container"}
        direction="row"
        justify={"space-between"}
        alignItems="flex-end"
      >
        {navBarItems.map((navBarItem) => (
          <Grid item key={navBarItem.content}>
            <li className={"navbar__list__item"}>
              <Link
                className={`${currentPage === navBarItem.content && "active"}`}
                to={`/${navBarItem.href.toLowerCase()}`}
              >
                {navBarItem.content}
              </Link>
            </li>
          </Grid>
        ))}
      </Grid>
    </NavbarList>
  );
};

export default Navbar;
