import React, {useState} from "react";
import {Grid, useMediaQuery} from "@material-ui/core";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import ToggleButton from "./components/Navbar/ToggleButton";

const StyledHeader = styled.header`
  margin: 0 5%;
  padding: 5% 0;
  font-family: "Arial Narrow", sans-serif;
  .header__logo {
    display: flex;
    font-size: 50px;
    margin-bottom: -7px;
  }
`;

const Header = ({currentPage, fontSizes}) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <StyledHeader isMenuOpen={isMenuOpen}>
      <Grid
        container
        direction="row"
        justify="space-between"
        wrap={isSmallScreen ? "wrap" : "nowrap"}
        alignItems="flex-end"
      >
        <Grid item xs={8} sm={4}>
          <div className="header__logo">XIAOYU YANG</div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <ToggleButton
            isMenuOpen={isMenuOpen}
            isMobileView={isSmallScreen}
            handleClick={handleClick}
          />
          <nav className={"header__nav"}>
            <Navbar
              currentPage={currentPage}
              isMenuOpen={isMenuOpen}
              isMobileView={isSmallScreen}
              fontSizes={fontSizes}
            />
          </nav>
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default Header;
