import React, {useCallback} from "react";
import styled from "styled-components";
import {Box, Grid} from "@material-ui/core";
import styles from "../../../UI/Layout.module.css";

const marginMap = {
  "Time’s Divergence": "54px 0 54px",
  undefined: "0"
}

const PaintingDetail = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: ${(props) => props.layout};
	align-items: ${(props) => (props.layout === "column" ? "flex-start" : "flex-end")};
	justify-content: flex-start;

	img {
		width: ${(props) => (props.layout === "column" ? "100%" : "60%")};
		margin-right: ${(props) => props.layout === "row" && "40px"};
		max-width: 1400px;
	}

	video {
		max-width: 1400px;
		width: 100%;
	}

	h4 {
		margin: 0 0 16px 0;
	}

	section.details {
		margin: ${(props) => {if (props.name.includes("Divergence")) {
      return "0 54px"
    } else {
      return "60px"
    }}};
	}

	p.description {
	}
`;

const PaintingPage = ({painting}) => {
  const renderPaintings = useCallback((painting) => {
    switch (painting.name) {
      case "The Floral Alarm":
        return (
          <Grid container spacing={4} direction={"column"} className={"paintingsWrapper"}>
            <Grid item sm={12} xl={10}>
              <img src={`../image/floral/1.jpg`} alt={"figure 1"} />
            </Grid>
            <Grid item sm={12} xl={10}><Grid container spacing={4}>
              {[...Array(9)].map((_, index) => (
                <Grid key={index} item sm={12} md={6} className={"paintings"}>
                  <img
                    src={`../image/floral/${index + 2}.jpg`}
                    alt={`figure ${index + 2}`}
                  />
                </Grid>
              ))}
            </Grid></Grid>
          </Grid>
        );
      case "Michelia Wilsonii Finet et Gagn":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                src={`../image/painting_detail/Michelia Wilsonii Finet et Gagn.png`}
                alt={painting.name}
              />
            </Grid>
            <Grid item xs={12}>
              <video controls autoPlay>
                <source src="../image/painting_detail/mono.mp4" type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        );
      case "Rhodiola Crenulata":
        return (
          <Grid container spacing={2}>
            <Grid item>
              <img
                src={`../image/painting_detail/${painting.name}.jpg`}
                alt={painting.name}
              />
            </Grid>
            <Grid item>
              <img
                src={`../image/painting_detail/${painting.name}.png`}
                alt={painting.name}
              />
            </Grid>
          </Grid>
        );
      case "Please Hear Me":
        return (
          <Grid container spacing={2}>
            {[...Array(3)].map((_, index) => (
              <Grid key={index} item xs={12}>
                <img
                  src={`../image/painting_detail/please_hear_me/${index + 1}.jpg`}
                  alt={`figure ${index + 1}`}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <video controls>
                <source src="../image/painting_detail/please_hear_me/Please_hear_me.mp4" type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        );
      default:
        return (
          <img
            src={`../image/painting_detail/${painting.name}.jpg`}
            alt={painting.name}
          />
        );
    }
  }, []);

  return (
    <PaintingDetail className={`${styles.fadeIn}`} {...painting}>
      {renderPaintings(painting)}
      <Box component="section" mt={5} className="details">
        <h4>{painting.name}</h4>
        {Object.entries(painting)
          .slice(2, -1)
          .map(
            ([key, value]) =>
              value && (
                <p
                  key={key}
                >
                  {`${key}: ${value}`}
                </p>
              ),
          )}
        {painting.description && <p className="description">{painting.description}</p>}
      </Box>
    </PaintingDetail>
  );
};

export default PaintingPage;
