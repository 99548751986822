import React from "react";
import styled from "styled-components";
import profile_photo from "./xiaoyu_profile.jpg";
import styles from "../../../UI/Layout.module.css";
import {Typography} from "@material-ui/core";

const AboutPageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	font-family: "Arial Narrow", serif;

	section:first-child {
		h3 {
			font-size: 24px;
		}

		p {
			font-size: 16px;
			line-height: 1.5em;
		}

		max-width: 50%;
	}

	section:last-of-type {
		max-width: 30%;
		padding-top: 30px;

		img {
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		section:first-child,
		section:last-of-type {
			max-width: 100%;
		}

		flex-direction: column-reverse;
		align-items: stretch;
	}
`;

const AboutPage = () => {
  return (
    <AboutPageWrapper className={styles.fadeIn}>
      <section>
        <h3>Biography</h3>
        <p>Xiaoyu Yang (杨晓雨)</p>
        <p>
          Xiaoyu Yang, an ecological artist hailing from China, has a background as a painter. Presently, she is
          pursuing a doctoral degree at Edith Cowan University. She holds a Master of Arts in Visual Arts, acquired from
          Queensland College of Art at Griffith University in Brisbane. Her scholarly contributions include articles in
          peer-reviewed journals, about ecological art, public art, and interactive art theories.
        </p>
        <p>
          A dedicated traveler, Xiaoyu Yang enriched her insights through a study exchange program in Taiwan, where she
          immersed herself in fine arts at the National Kaohsiung Normal University. Her artistic endeavors revolve
          around ecological and environmental concerns, notably species depletion, with a unique blend of traditional
          Chinese painting and Australian artistic techniques. Her artworks have graced exhibitions across diverse
          cities such as Brisbane, Melbourne, Cairns, Changsha, and Shijiazhuang.
        </p>
      </section>
      <section>
        <img src={profile_photo} alt={"profile photo"} />
      </section>
    </AboutPageWrapper>
  );
};

export default AboutPage;
