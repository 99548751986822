import React from "react";
import styled from "styled-components";
import {Button, Grid} from "@material-ui/core";
import Carousel from "./components/Carousel";
import styles from "../../../UI/Layout.module.css";
import {Link} from "react-router-dom";

const images = [
  {
    alt: "Rhodiola crenulata",
    path: "image/slide_1.jpg",
  },
  {
    alt: "Michelia wilsonii Finet et Gagn",
    path: "image/slide_2.jpg",
  },
  {
    alt:"Please hear me",
    path:"image/slide_4.jpg",
    isDarkImage: true,
  },
];

const Home = styled.div`
	.buttonWrapper {
		width: 100%;

		& a {
			text-decoration: none;
		}
	}

	.viewWorksButton {
		width: 100%;
		height: 60px;
		border-radius: 5px;
		background-color: #cace91;
		font-size: ${({fontSizes}) => (fontSizes ? fontSizes.secondary : 25)}px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: white;
		border: none;
	}

	.viewWorksButton:hover {
		background-color: #b2bc74;
	}

	.gridCarouselItem {
		width: 100%;
		margin-bottom: 2%;
	}
`;

const HomePage = ({fontSizes}) => {
  return (
    <Home className={styles.fadeIn} fontSizes={fontSizes}>
      <Grid container direction="column" alignItems={"center"}>
        <Grid className="gridCarouselItem" item xs={12}>
          <Carousel images={images} pause={false} autoplay={false} />
        </Grid>
        <Grid item className="buttonWrapper" xs={12} md={4} xl={3}>
          <Link to={"/works"}>
            <Button className="viewWorksButton" variant="contained" disableElevation>
              VIEW ALL WORKS
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Home>
  );
};

export default HomePage;
