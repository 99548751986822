import React from "react";
import HomePage from "./components/HomePage";
import ExhibitionsPage from "./components/ExhibitionsPage";
import WorksPage from "./components/WorksPage";
import styles from "../UI/Layout.module.css";
import PaintingPage from "./components/PaintingPage";
import PublicationsPage from "./components/PublicationsPage/Publications";
import AboutPage from "./components/AboutPage";
import NewsPage from "./components/NewsPage";
import ContactPage from "./components/ContactPage/ContactPage";

const Page = ({fontSizes, currentPage, painting}) => {
  return (
    <main className={styles.page}>
      {currentPage === "HOME" && <HomePage fontSizes={fontSizes} />}
      {currentPage === "EXHIBITIONS" && <ExhibitionsPage fontSizes={fontSizes} />}
      {currentPage === "WORKS" && <WorksPage fontSizes={fontSizes} />}
      {currentPage === "PUBLICATIONS" && <PublicationsPage fontSizes={fontSizes} />}
      {currentPage === "PAINTING" && (
        <PaintingPage painting={painting} fontSizes={fontSizes} />
      )}
      {currentPage === "ABOUT" && <AboutPage fontSizes={fontSizes} />}
      {currentPage === "NEWS" && <NewsPage fontSizes={fontSizes} />}
      {currentPage === "CONTACT" && <ContactPage fontSizes={fontSizes} />}
    </main>
  );
};

export default Page;
