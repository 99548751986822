import React from "react";
import styled from "styled-components";
import styles from "../../../UI/Layout.module.css";
import {Card, CardContent, makeStyles} from "@material-ui/core";

const Publications = styled.section`
	display: flex;
	flex-direction: column;
	min-height: 100%;

	a {
		color: black;
		transition: 0.3s ease-in-out;
	}

	a:hover {
		opacity: 0.5;
	}

	p {
		font-size: ${({fontSizes}) => (fontSizes ? fontSizes.text : 20)}px;
	}
`;

const useStyles = makeStyles({
  root: {
    border: 0,
    margin: 0,
    padding: "0",
    fontFamily: "Arial Narrow",
  },
  content: {
    padding: 0,
    margin: "5px",
  },
});

const PublicationsPage = (props) => {
  const classes = useStyles();
  return (
    <Publications
      className={styles.fadeIn}
      fontSizes={props.fontSizes}
      height={props.footerHeight}
    >
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          Xiaoyu, Y (2022. 11), <a href={"https://www.cnki.net/KCMS/detail/detail.aspx?dbcode=CJFD&dbname=CJFDLAST2023&filen\n" +
          "ame=GOYS202206007&uniplatform=OVERSEA&v=qO_bXPz7EGgW_gYqVLM71IouRwkR3wdA\n" +
          "qIBm046_Jg4zMO0SasLaP2seFtR5W_-n"}>The Development and Practice of Australian Art Museum Education.</a> Public Art, no.6, pp. 50-59.
        </p>
      </Card>
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          Xiaoyu, Y (2021.2), <a
          href="http://lib.cqvip.com/Qikan/Article/Detail?id=7107141871&from=Qikan_Search_Index">Multiculturalism -
          Overview of the 9th Asia Pacific
          Triennial of Contemporary Art.</a> Art Museum, no.1, pp. 98-103.
        </p>
      </Card>
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          {"Xiaoyu, Y (2021.4), "}
          <a
            href={
              "https://oversea.cnki.net/KCMS/detail/detail.aspx?dbcode=CJFD&dbname=CJFDAUTO&filename=GOYS202102013&v=0rI%25mmd2BkkL3OlMAE7jVimYyxp2tGaAjLmhXjfbBSvq%25mmd2BBXxCUKoMTCBnD9rr6VwkwwX0"
            }
          >
            Interactive Art and Public Space: A Case Study of Vivid Sydney.
          </a>
          {" Public Art, no.2, pp. 93-101."}
        </p>
      </Card>
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          Xiaoyu, Y (2020.10), Transboundary and Return: Contemporary Ecological Art
          Practice. China Artists Association, Thesis Collection of the National Art
          Summit Forum: Chongqing, forthcoming.
        </p>
      </Card>
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          {"Xiaoyu, Y (2020.8), "}
          <a
            href={
              "https://oversea.cnki.net/KCMS/detail/detail.aspx?dbcode=CJFD&dbname=CJFDLAST2020&filename=GOYS202004004&v=PQaYvX9YJJZ7SYHkj8hpzVdk%25mmd2BVl7NP6zNhNGMiNyWP8IYZ3yBOn7UaXAi8KvcVDQ"
            }
          >
            A Research on Public Art Policy in Queensland, Australia.
          </a>
          {" Public Art, no.4, pp. 21-33."}
        </p>
      </Card>
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          {"Xiaoyu, Y (2020.4), "}
          <a
            href={
              "https://oversea.cnki.net/KCMS/detail/detail.aspx?dbcode=CJFD&dbname=CJFDLAST2020&filename=GOYS202002013&v=PQaYvX9YJJaHBqnJHrj2gWW980ZeBpo4lPQI2ObAmnkm0zTtiREudtU0xxO6KlTv"
            }
          >
            Urban Public Art Construction from the Perspective of Ecological Art.
          </a>
          {" Public Art, no.2, pp. 88-95."}
          <br />
          (This paper has also been collected by the 5th Public Art Forum at Guangdong,
          China)
        </p>
      </Card>
      <Card variant={"outlined"} className={classes.root}>
        <p className={classes.content}>
          Xiaoyu, Y (2019.10), The Consideration of Art and Science in the Age of
          Artificial Intelligence. Tsinghua University, Thesis Collection of the 5th Art
          and Science International Symposium, pp. 136-142.
        </p>
      </Card>
    </Publications>
  );
};

export default PublicationsPage;
