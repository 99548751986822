import React from "react";
import styled from "styled-components";

const IndicatorWrapper = styled.div`
  display: flex;

  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  right: 50%;
  bottom: 5%;
  transform: translateX(50%);

  & .dot {
    height: 12px;
    width: 12px;
    margin: 0 6px 0;
    background-color: rgb(123, 123, 123);
    border-radius: 50%;
    display: inline-block;
  }

  & .dot.dot--active {
    background-color: rgb(168, 168, 168);
  }
`;

const Indicator = ({numImages, currentImageIndex, changeSlideHandler}) => {
  return (
    <IndicatorWrapper>
      {[...Array(numImages).keys()].map((key) => {
        return (
          <span
            key={key}
            onClick={() => changeSlideHandler(key)}
            className={`dot ${key === currentImageIndex && "dot--active"}`}
          />
        );
      })}
    </IndicatorWrapper>
  );
};

export default Indicator;
