import React, {useEffect} from "react";
import styled from "styled-components";
import {Grid, Icon} from "@material-ui/core";
import {loadCSS} from "fg-loadcss";
import styles from "../UI/Layout.module.css";

const StyledFooter = styled.footer`
	background-color: #cace91;
	padding: 0 5%;
	flex-shrink: 0;

	p{
		color: white;
	}
    .privacy {
        margin: 6px 0;
    }

	.cut-off {
		padding: 0;
		background: rgba(255, 255, 255, 0.38);
		border-bottom: rgba(255, 255, 255, 0) solid 1px;
		width: 80%; // make element not shrink when use align -item center
	}

	.icons {
		margin-bottom: 8px;
	}

	.fab {
		color: rgba(255, 255, 255, 0.75);
		font-size: 30px;
		cursor: pointer;
		margin: 8px;
	}

	.fab:hover {
		color: rgb(255, 255, 255);
		transition: color 0.2s ease-in-out;
	}
`;

const Footer = () => {
  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  return (
    <StyledFooter className={`${styles.fadeIn} ${styles.paintingsGrid}`}>
      <Grid
        container
        direction={"column"}
        justify={"flex-start"}
        alignItems={"center"}
        wrap={"nowrap"}
      >
        <Grid item xs={12}>
          <p>XIAOYU YANG &copy; {new Date().getFullYear()}, ALL RIGHTS RESERVED</p>
        </Grid>
        <Grid item className={"cut-off"} xs={8} />
        <Grid item xs={12}>
          <p className="privacy">We understand that protecting your privacy is a great responsibility and we make every effort to ensure that
            you have control over your private data.</p>
        </Grid>
        <Grid item className={"icons"}>
          <Icon className="fab fa-linkedin-in" color="primary" />
          <a href="https://www.instagram.com/anyway4483/"><Icon className="fab fa-instagram" color="primary" /></a>
        </Grid>
      </Grid>
    </StyledFooter>
  );
};

export default Footer;
