import React from "react";
import Painting from "./components/Painting";
import {Grid} from "@material-ui/core";
import styles from "../../../UI/Layout.module.css";

const paintings = [
  {
    name: "Please Hear Me",
    pathname: "image/6_Please_hear_me.jpg",
  },
  {
    name: "Rhodiola Crenulata",
    pathname: "image/1_Rhodiola crenulata.jpg",
  },
  {
    name: "Michelia Wilsonii Finet et Gagn",
    pathname: "image/2_Michelia wilsonii Finet et Gagn.jpg",
  },
  {
    name: "Changium Smyrnioides Wolff",
    pathname: "image/3_Changium smyrnioides Wolff.jpg",
  },
  {
    name: "Time's Divergence",
    pathname: "image/4_Times_Divergence.jpg",
  },
  {
    name: "The Floral Alarm",
    pathname: "image/5_Floral Alarm.jpg",
  },
];

const WorksPage = () => {
  return (
    <Grid container spacing={4} className={`${styles.fadeIn}`}>
      {paintings.map((painting, index) => {
        return (
          <Grid item key={index} xs={12} sm={6} md={4} xl={3}>
            <Painting
              painting={painting}
              key={index}
              isLast={index === paintings.length - 1}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default WorksPage;
